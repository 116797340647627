import { useParams } from "react-router-dom";
import { Row, Col } from 'react-simple-flex-grid';

import useAPI from "../hooks/useNftmApi";
import NftCard from "./NftCard";
import hiddenNft from "./../hiddenObjects/hiddenNft.json";

const stateToText = {
  free: "Available",
  error: "Error",
  reserved: "Reserved",
  sold: "Sold",
};


function Project() {
  let { id } = useParams("id");
  const { isLoading, result, error } = useAPI("GetNfts", `${id}/all`);

  return (
    <div style={{margin: "0 10% 0 10%", "text-align": "center"}}>
      <Row gutter={100} width={"80%"}>
        {isLoading || !result ? "" : (
          result.filter((item) => !hiddenNft.includes(item.id)).map((nft) => {
            return (
              <Col span={4} key={nft.id}>
                <div className={`project-item is-${stateToText[nft.state].toLowerCase()}`}>
                  <img src={nft.gatewayLink} width={"100%"} alt={nft.name} />
                  <div className="project-item-content">
                    <h2>
                      {nft.name}
                    </h2>
                    <NftCard nftObject={nft} projectId={id}></NftCard>
                  </div>
                </div>
              </Col>
            );
          })
        )}
      </Row>
    </div>
  );
}

export default Project;
