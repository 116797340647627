import { Row, Col } from 'react-simple-flex-grid';

const About = () => (
  <div className="container">
    <Row gutter={100}>
      {/* <Col span={4}>
        <img src="/about.jpeg" alt="" width="100%" height=""/>
      </Col> */}
      <Col span={12}>
        <h1>ABOUT</h1>

        <p>autre coeur translates as 'other heart' and started for me as an anonymous outlet and a means to develop my passion
          for art and computing.</p>
        <p>
          all pieces you see here have been made using biggan and stylegan ai, generated from parent images until the end
          piece is completely unrecognisable from the source.​​​​​​​ i work mainly in artbreeder and have my own gan in development.
        </p>
        <p>the majority of pieces are meticulously tweaked to express the artists vision and flow through a particular theme.
          there are, of course, some happy accidents.
        </p>
        <hr />
        <h2>POLICY IDS</h2>
        <ul className="list-unstyled">
          <li><span className="text-underline">Mariana Peak</span> <br />928fc36daf2fc95a11bef3f4e32d32aa7029de38c48d6dc01314f0dc</li>
          <li><span className="text-underline">GAN Collection</span> <br /> 3c61e403c8539ca973924f6e6d88b6738ed1d4561a941792abfa9d83<br />
          ff51db2f93f3d763826b1cfbc6faa7d4d0f7dd5182290e4ea17f8d73</li>
          <li><span className="text-underline">Retired:</span>
            <ul>
              <li>d3d8339d536bde3ed39cd8fc7e6a1d23421b207bbb4c448788744921</li>
              <li>661317f8f3eddd43d251ac487e7cfa1805eef24e651775b8158dfe50</li>
              <li>29821145a32fe99a4f9390e2514d38a4b00100b80007afe74198af70</li>
              <li>370a099ce01a3fe532491d386b19f8683ddce8ca650092769c3691ee</li>
              <li>d1d33f275c76a329b1491151c29fa15f7974ea97af453acc9503fdd8</li>
            </ul>
          </li>
        </ul>
      </Col>
    </Row>
  </div>
);

export default About;
