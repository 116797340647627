import { Link } from 'react-router-dom';
import { Row, Col } from 'react-simple-flex-grid';


const Header = () => (
  <header>
    <Row gutter={100} className="header-row">
      <Col span={6}>
        <div className="brand">
          <Link to="/">autre coeur</Link>
        </div>
      </Col>
      <Col span={6}>
        <nav>
          <Link to="/">collections</Link>
          <Link to="/about">about</Link>
          <Link to="https://twitter.com/autrecoeur" target="_blank"><i className="fab fa-twitter"></i></Link>
          <Link to="http://instagram.com/autrecoeur/" target="_blank"><i className="fab fa-instagram"></i></Link>
          <Link to="https://discord.gg/rMdGPy5wS8" target="_blank"><i className="fab fa-discord"></i></Link>
        </nav>
      </Col>
    </Row>
  </header>
);

export default Header;
