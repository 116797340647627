import { useParams, Redirect  } from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Link } from "react-router-dom";

import styled from "styled-components";
import React, { useState } from 'react';
import useAPI from "../hooks/useNftmApi";
import BuyModal from "./BuyModal";
import Prices from "./../prices/prices.json";

function NftCard(props) {
  let { id } = useParams(["id"]);

  const projectId = props.projectId
  const [showBuyModal, setShowBuyModal] = useState(false);

  const nftState = props.nftObject.state
  //const { isLoading, result, error } = useAPI("CancelAddressReservation", `${projectId}/addr1v85fy00lux5lvjcmm8sddkemlw5r93n4e7cgcp4qmvwpy5cn4mvhe`);


  const Button = styled.button`
      color: white;
      padding: 15px 45px;
      outline: 0;
      text-transform: uppercase;
      margin: 10px 0px;
      cursor: pointer;
      transition: ease background-color 250ms;
      width: 100%;
      &:disabled {
        cursor: default;
        opacity: 0.7;
      }
      &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      background-color: ${props => props.state == 'reserved' ? "#AAAAAA" :  props.state == 'sold' ? '#AAAAAA' : "#000"}
    `;


  return (
    <div>
        <p>{ projectId in Prices ? Prices[projectId] + " ADA" : "On Request"}</p>
        <Link to={`/project/${projectId}/nft/${props.nftObject.id}/details`}>
        <Button
            state={nftState}
            disabled={nftState == 'reserved' ? false : nftState == 'sold' ? true : false}
        >
            {nftState == 'reserved' ? nftState : nftState == 'sold' ? nftState : "Buy Now"}
        </Button>
        </Link>
    </div>
    );
}

export default NftCard;
