import { Link } from "react-router-dom";
import { Row, Col } from 'react-simple-flex-grid';

import useAPI from "../hooks/useNftmApi";
import hiddenCollections from "./../hiddenObjects/hiddenCollections.json";

function Home() {
  const { isLoading, result, error } = useAPI("ListProjects");

  return (
    <main>
      <div className="home">
        <div className="project-list">
          {isLoading || !result ? "" : (
            <Row gutter={100}>
              {result.filter(project => project.total > 0 && !hiddenCollections.includes(project.id)).map((project, index) => (
                <Col span={6} key={project.id}>
                  <div className="project-list-item">
                    <Link to={`project/${project.id}`}>
                      <div className="project-list-item__image" style={{backgroundImage:`url(/${project.id}.jpeg)`}}>
                        <img src={`/${project.id}.jpeg`} alt={project.projectname}/>
                      </div>
                    </Link>
                    <div className="project-list-item__content">
                      <h3>
                        <Link to={`project/${project.id}`}>
                          {project.projectname}
                        </Link>
                      </h3>
                      <h4>
                        ({project.total} NFTs)
                      </h4>
                    </div>
                  </div>
                </Col>
                )
              )}
            </Row>
          )}
        </div>
      </div>
    </main>
  );
}

export default Home;