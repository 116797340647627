import { useParams, Redirect } from "react-router-dom";
import { Row, Col } from 'react-simple-flex-grid';
import { Link } from "react-router-dom";
import styled from "styled-components";
import React, { useState } from 'react';
import BuyModal from "./BuyModal";
import Prices from "./../prices/prices.json";

import useAPI from "../hooks/useNftmApi";
import NftCard from "./NftCard";

const stateToText = {
  free: "Available",
  error: "Error",
  reserved: "Reserved",
  sold: "Sold",
};

function Details() {
  let { id, nftId } = useParams(["id", "nftId"]);

  const { isLoading, result, error } = useAPI("GetNftDetailsById", `${id}/${nftId}`);
  const [generateAddress, setGenerateAddress] = useState(false);

const Button = styled.button`
      color: white;
      padding: 15px 45px;
      outline: 0;
      text-transform: uppercase;
      margin: 10px 0px;
      cursor: pointer;
      transition: ease background-color 250ms;
      width: 100%;
      &:disabled {
        cursor: default;
        opacity: 0.7;
      }
      &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      background-color: ${props => props.state == 'reserved' ? "#AAAAAA" :  props.state == 'sold' ? '#AAAAAA' : "#000"}
    `;

  return (
    <div className={"details-bg"}>
      <div className={"details-content"}>
          {isLoading || !result ? "" : (
        <Row gutter={100}>
            <Col span={1} offset={11} className={"close"} onClick={() => { }}>
              <Link to={`/project/${id}`}>X</Link>
            </Col>
            <Col span={6} className={"preview-box"} onClick={() => { }}>
              <img src={result.ipfsGatewayAddress} style={{maxWidth: "100%"}} />
            </Col>
            <Col span={6} className={"details-box"} onClick={() => { }}>
                 <h2>{result.name}</h2>
                 {generateAddress ? <RedirectComp projectId={id} nftId={nftId} price={Prices[id]} /> : ""}
                 <Button
                    state={result.state}
                    disabled={result.state == 'reserved' ? false : result.state == 'sold' ? true : false}
                    onClick={() => {setGenerateAddress(true)}}
                  >
                    {result.state == 'reserved' ? result.state : result.state == 'sold' ? result.state : "Commit to Buy"}
                </Button>
            </Col>
        </Row>
          )}
      </div>
    </div>
  );
}
export default Details;

function RedirectComp(props) {

  const oneADA = 1000000;
  const price = oneADA * props.price;

  const { isLoading: addressIsLoading, result: addressResult, error: addressError, trigger: addressTrigger} = useAPI("GetAddressForSpecificNftSale", `${props.projectId}/${props.nftId}/1/${price}`);

  return (
   addressResult != null && 'paymentAddress' in addressResult ? <Redirect to={`/project/${props.projectId}/address/${addressResult.paymentAddress}`} /> : ""
  );
}