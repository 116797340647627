
const Footer = () => (
   <footer>
       <p>
            Copyright 2021 | All Rights Reserved
       </p>
       <p>
        <small>
            Disclaimer: You understand and agree that you are solely responsible for determining the nature, potential value,
            suitability and appropriateness when buying NFT's for yourself. All content copyrights remains with autre coeur.
        </small>
       </p>
   </footer>
  );

  export default Footer;
