import { useParams, Redirect } from "react-router-dom";
import { Row, Col } from 'react-simple-flex-grid';
import { Link } from "react-router-dom";
import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import BuyModal from "./BuyModal";
import Loader from "react-loader-spinner";

import useAPI from "../hooks/useNftmApi";
import NftCard from "./NftCard";
import PaymentConfirmed from "./PaymentConfirmed";

const stateToText = {
  free: "Available",
  error: "Error",
  reserved: "Reserved",
  sold: "Sold",
};

const BASE_URI = "https://umsd4xtah4.execute-api.us-west-2.amazonaws.com/prod";



function Payment() {
  let { id, address } = useParams(["id", "address"]);

    let check_address = async (urlExt, params) => {
        const baseUri = `${BASE_URI}/${urlExt}`;
        const uri = params ? `${baseUri}/${params}` : baseUri;
        const response = await fetch(uri)
        const nft = await response.json();
        return nft
    }

  const [paymentObject, setPaymentObject] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);

    useEffect(() => {
      const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
          check_address("CheckAddress", `${id}/${address}`).then(nft => {
           setPaymentObject(nft)
         });
      }, 5000)

      return () => clearInterval(intervalId); //This is important

    }, [])


const Button = styled.button`
      color: white;
      padding: 15px 45px;
      outline: 0;
      text-transform: uppercase;
      margin: 10px 0px;
      cursor: pointer;
      transition: ease background-color 250ms;
      width: 100%;
      &:disabled {
        cursor: default;
        opacity: 0.7;
      }
      &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      background-color: ${props => props.state == 'reserved' ? "#AAAAAA" :  props.state == 'sold' ? '#AAAAAA' : "#000"}
    `;

  return (
    <div className={"details-bg"}>
          {!paymentObject ? (
            <Loader
                type="Ball-Triangle"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
          ) : ( paymentObject.state == "expired" ? <Redirect to={`/project/${id}`} /> :
        <Row gutter={100}>
            <Col span={1} offset={11} className={"close"} onClick={() => { }}>
                <Link to={`/project/${id}`}>X</Link>
            </Col>
            <Col span={6} offset={3} className={"details-box"} onClick={() => { }}>
                 <h2>{paymentObject.reservedNft[0].name}</h2>
                 {'senderAddress' in paymentObject && paymentObject.senderAddress != null && 'payDateTime' in paymentObject && paymentObject.payDateTime != null ? <PaymentConfirmed paymentAddress={address} projectId={id} /> : <BuyModal paymentAddress={address} paymentObject={paymentObject} /> }
            </Col>
        </Row>
          )}
    </div>
  );
}

export default Payment;
