import { useParams } from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import Prices from "./../prices/prices.json";
import styled from "styled-components";
import React, { useState } from 'react';
import useAPI from "../hooks/useNftmApi";

function fancyTimeFormat(duration)
{
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">Expired</div>;
  }

  return (
    <div className="timer">
      <div className="text">Remaining</div>
      <div className="value">{fancyTimeFormat(remainingTime)}</div>
    </div>
  );
};

function BuyModal(props) {
  let { id } = useParams(["id"]);
  const paymentObject = props.paymentObject
  const paymentAddress = props.paymentAddress
  const [showBuyModal, setShowBuyModal] = useState(false);

  const oneADA = 1000000;
  const price = oneADA * 50;
  const nftState = paymentObject.state

  return ( !!paymentObject && 'expiresDateTime' in paymentObject ? <div>
              <span>Don't close this window until your transaction is confirmed or expired or someone else will be able to take your place in line.</span>
              <h2>Complete your transaction</h2>
              <hr />
              <br />
              <div className={"countdown-timer"}>
                <CountdownCircleTimer
                  style={{margin: "auto !important"}}
                  isPlaying
                  duration={(Date.parse(paymentObject.expiresDateTime) - Date.now()) / 1000}
                  colors={[
                    ['#004777', 0.33],
                    ['#F7B801', 0.33],
                    ['#A30000', 0.33],
                  ]}
                >
                  {renderTime}
                </CountdownCircleTimer>
              </div>
              {/* <QRCode
                              eyeRadius={[
                              	{
                              		outer: [10, 10, 0, 10],
                              		inner: [0, 10, 10, 10],
                              	},
                              	[10, 10, 10, 0], // top/right eye
                              	[10, 0, 10, 10], // bottom/left
                              ]}
                              value={paymentAddress}
                              size={200}
                            /> */}
          <h3>ADA Address:</h3>
          <input value={paymentAddress} className={"ada-address-input"} disabled /><button onClick={() => {navigator.clipboard.writeText(paymentAddress)}}>Copy</button>

          <h3>Amount in ADA:</h3>
          <input value={paymentObject.hasToPay / oneADA} className={"ada-address-input"} disabled /><button onClick={() => {navigator.clipboard.writeText(paymentObject.hasToPay / oneADA)}}>Copy</button>
          <br />
          <br />
    </div> : ""
    );
}

export default BuyModal;
