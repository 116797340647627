import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import "./App.css";
import "react-simple-flex-grid/lib/main.css";
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import Header from "./components/Header";
import Project from "./components/Project";
import Home from "./components/Home";
import Details from "./components/Details";
import Payment from "./components/Payment";
import About from "./components/About";
import Footer from "./components/Footer";

function App() {
  let location = useLocation();

  return ([
      <Header />,
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="alert"
          timeout={100}
        >
          <Switch location={location}>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/project/:id/address/:address">
              <Payment />
            </Route>
            <Route path="/project/:id/nft/:nftId/details">
              <Details />
            </Route>
            <Route path="/project/:id">
              <Project />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>,
      <Footer />
  ]);
}

export default App;
