import { useParams, Redirect } from "react-router-dom";
import { Row, Col } from 'react-simple-flex-grid';
import { Link } from "react-router-dom";
import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import BuyModal from "./BuyModal";
import { Tick } from 'react-crude-animated-tick';
import useAPI from "../hooks/useNftmApi";
import NftCard from "./NftCard";

const BASE_URI = "https://umsd4xtah4.execute-api.us-west-2.amazonaws.com/prod";

function PaymentConfirmed(props) {

  let projectId = props.projectId
  let paymentAddress = props.paymentAddress

  const { isLoading: addressCheckLoading, result: addressCheckResult, error: addressCheckError } = useAPI("CheckAddress", `${projectId}/${paymentAddress}`);
  const [isSent, setIsSent] = useState(false);
  let paymentObject = addressCheckResult

  if (addressCheckResult) {
  let reservedNft = paymentObject.reservedNft[0]

  let mintAndSend = async (urlExt, params) => {
    const baseUri = `${BASE_URI}/${urlExt}`;
    const uri = params ? `${baseUri}/${params}` : baseUri;
    const response = await fetch(uri)
    const nft = await response.json();
    return nft
  }

  if (addressCheckResult && addressCheckResult.reservedNft[0].minted == false) {
    mintAndSend("MintAndSendSpecific", `${projectId}/${reservedNft.id}/1/${paymentObject.senderAddress}`).then(nft => {
        setTimeout(() => {setIsSent(true)}, 4000)
    });
  }
  }

  return (addressCheckResult ? [
    <Tick size={200} />,
    <h1>Payment completed! Thank you</h1>,
    <h1>NFT sent to you Wallet: <i>{paymentObject.senderAddress}</i></h1>,
    isSent ? <Redirect to={`/project/${projectId}`} /> : ""
  ] : "");
}

export default PaymentConfirmed;
